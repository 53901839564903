import { default as index4IWsoJZYrAMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/index.vue?macro=true";
import { default as _91id_930AzexkJxkbMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/job/[id].vue?macro=true";
import { default as _91id_935shkkfQps7Meta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/manage/[id].vue?macro=true";
import { default as _91id_93OsMDg5BTkUMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/upload/[id].vue?macro=true";
import { default as indexXhY4heehFfMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/identifiers/index.vue?macro=true";
import { default as _91id_93XFwXnbDOJjMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/identifiers/manage/[id].vue?macro=true";
import { default as authlqNdybz5zEMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/auth.vue?macro=true";
import { default as copyright_45claim0M56SKtDacMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/copyright-claim.vue?macro=true";
import { default as indexTahSc0M7X1Meta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/test-devices/index.vue?macro=true";
import { default as _91id_93mRBBX7VJoIMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/test-devices/manage/[id].vue?macro=true";
import { default as createSpJ8jS1OyVMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/tokens/create.vue?macro=true";
import { default as indexYiitrXYVftMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/tokens/index.vue?macro=true";
import { default as indexSpWU4UxZRcMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/payment-providers/index.vue?macro=true";
import { default as stripekZCBU7uPqKMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/payment-providers/stripe.vue?macro=true";
import { default as indexmZQQZXXZwzMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/products/index.vue?macro=true";
import { default as _91id_93PCrqZoALg4Meta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/products/manage/[id].vue?macro=true";
import { default as indexKII26ZB3LbMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/index.vue?macro=true";
import { default as createik9AEiDh2uMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/copyright-infringements/create.vue?macro=true";
import { default as indexUKOgvO84tkMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/copyright-infringements/index.vue?macro=true";
import { default as featuresG6r6lmZqzPMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/features.vue?macro=true";
import { default as manageltCU0Y4EvuMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/info/manage.vue?macro=true";
import { default as overviewbPFcR2n9KfMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/overview.vue?macro=true";
export default [
  {
    name: "apps-binary-packages",
    path: "/apps/binary-packages",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/index.vue")
  },
  {
    name: "apps-binary-packages-job-id",
    path: "/apps/binary-packages/job/:id()",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/job/[id].vue")
  },
  {
    name: "apps-binary-packages-manage-id",
    path: "/apps/binary-packages/manage/:id()",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/manage/[id].vue")
  },
  {
    name: "apps-binary-packages-upload-id",
    path: "/apps/binary-packages/upload/:id()",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/upload/[id].vue")
  },
  {
    name: "apps-identifiers",
    path: "/apps/identifiers",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/identifiers/index.vue")
  },
  {
    name: "apps-identifiers-manage-id",
    path: "/apps/identifiers/manage/:id()",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/identifiers/manage/[id].vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: authlqNdybz5zEMeta || {},
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/auth.vue")
  },
  {
    name: "copyright-claim",
    path: "/copyright-claim",
    meta: copyright_45claim0M56SKtDacMeta || {},
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/copyright-claim.vue")
  },
  {
    name: "developer-test-devices",
    path: "/developer/test-devices",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/test-devices/index.vue")
  },
  {
    name: "developer-test-devices-manage-id",
    path: "/developer/test-devices/manage/:id()",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/test-devices/manage/[id].vue")
  },
  {
    name: "developer-tokens-create",
    path: "/developer/tokens/create",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/tokens/create.vue")
  },
  {
    name: "developer-tokens",
    path: "/developer/tokens",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/tokens/index.vue")
  },
  {
    name: "finances-payment-providers",
    path: "/finances/payment-providers",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/payment-providers/index.vue")
  },
  {
    name: "finances-payment-providers-stripe",
    path: "/finances/payment-providers/stripe",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/payment-providers/stripe.vue")
  },
  {
    name: "finances-products",
    path: "/finances/products",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/products/index.vue")
  },
  {
    name: "finances-products-manage-id",
    path: "/finances/products/manage/:id()",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/products/manage/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/index.vue")
  },
  {
    name: "legal-copyright-infringements-create",
    path: "/legal/copyright-infringements/create",
    meta: createik9AEiDh2uMeta || {},
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/copyright-infringements/create.vue")
  },
  {
    name: "legal-copyright-infringements",
    path: "/legal/copyright-infringements",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/copyright-infringements/index.vue")
  },
  {
    name: "legal-features",
    path: "/legal/features",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/features.vue")
  },
  {
    name: "legal-info-manage",
    path: "/legal/info/manage",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/info/manage.vue")
  },
  {
    name: "legal-overview",
    path: "/legal/overview",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/overview.vue")
  }
]