<template>
  <div class="flex h-full flex-col">
    <v-header>
      <template v-if="route.name !== 'auth'">
        <template v-if="userStore.data">
          <span>{{ userStore.data.email }}</span>
          <v-button
            variant="primary"
            @click="userStore.logout"
          >
            Logout
          </v-button>
        </template>
        <v-button
          v-else
          variant="primary"
          to="/auth"
        >
          Login
        </v-button>
      </template>
    </v-header>

    <v-container class="flex flex-grow gap-8">
      <v-menu class="shrink-0 basis-[18rem]">
        <v-menu-group title="Apps">
          <v-menu-item to="/apps/identifiers">Apps (identifiers)</v-menu-item>
          <v-menu-item to="/apps/binary-packages">Builds (IPAs)</v-menu-item>
        </v-menu-group>
        <v-menu-group title="Financial">
          <v-menu-item to="/finances/products"> Products and subscriptions</v-menu-item>

          <v-menu-item to="/finances/payment-providers"> Payment Providers </v-menu-item>

          <v-menu-item
            to="/finances/purchases"
            disabled
          >
            Purchases
          </v-menu-item>

          <v-menu-item
            to="/finances/customers"
            disabled
          >
            Your customers
          </v-menu-item>
          <v-menu-item
            to="/finances/invoices"
            disabled
          >
            appdb Services invoices
          </v-menu-item>
          <v-menu-item
            to="/finances/statistics"
            disabled
          >
            Statistics
          </v-menu-item>
        </v-menu-group>
        <v-menu-group title="Legal">
          <v-menu-item to="/legal/overview">Overview</v-menu-item>
          <v-menu-item to="/legal/features">Platform features</v-menu-item>
          <v-menu-item to="/legal/copyright-infringements">Copyright infringements</v-menu-item>
          <v-menu-item to="https://publisherid.appdb.to">Publisher ID</v-menu-item>
        </v-menu-group>
        <v-menu-group title="Developer area">
          <v-menu-item to="/developer/tokens">Service keys & tokens</v-menu-item>
          <v-menu-item to="/developer/test-devices">Test devices</v-menu-item>
          <v-menu-item to="https://rtfm.dbservices.to/">Documentation</v-menu-item>
        </v-menu-group>
        <v-menu-group title="Settings">
          <v-menu-item
            to="/settings/notifications"
            disabled
          >
            Email notifications
          </v-menu-item>

          <v-menu-item
            to="/developer/events-notifications"
            disabled
          >
            Events notifications
          </v-menu-item>
        </v-menu-group>
      </v-menu>

      <main class="flex-grow">
        <v-static-notifications />
        <slot />
      </main>
    </v-container>
    <v-footer />
  </div>
</template>

<script setup lang="ts">
const route = useRoute()

const userStore = useUserStore()
</script>
