import type { Agreement, CopyrightClaim, Defaults, LegalEntity, LegalEntityType, LegalFile, RequestOptions } from "core/types/api"

export function getAgreements(params: { ids?: number[]; required_for?: "copyright_claims" | "account_creation" } = {}) {
  return api.get<Agreement[]>("get_agreements", {
    params,
  })
}

export function signAgreement(data: { id: number; signatory_name: string; "2fa_code": string }) {
  return api.post("sign_agreement", { data })
}

export function submitCopyrightClaim(data: {
  url: string
  name?: string
  phone?: string
  email?: string
  license_text: string
  "g-recaptcha-response"?: string
}) {
  return api.post("submit_copyright_claim", {
    data,
  })
}

export function getCopyrightClaims() {
  return api.get<CopyrightClaim[]>("get_copyright_claims")
}

export function getLegalEntity(params: { id?: number } = {}) {
  return api.get<LegalEntity>("get_legal_entity", { params, silent: true })
}

export function editLegalEntity(data: {
  id?: number
  address: string
  type: LegalEntityType
  name: string
  country: string
  vat_number: string
  registry_code: string
  bank_name: string
  bank_swift: string
  bank_address: string
  bank_iban: string
  representative_name: string
  representative_number: string
  representative_email: string
  representative_position: string
  signatory_name: string
  signatory_number: string
  signatory_email: string
  signatory_position: string
}) {
  return api.post<LegalEntity>("edit_legal_entity", { data })
}

export function addLegalFile(data: { name: string; legal_entity_id: number; pdf?: File }, options: RequestOptions = {}) {
  return api.post<LegalFile>("add_legal_file", { data, ...options })
}

export function deleteLegalFile(data: { id: number }) {
  return api.post("delete_legal_file", { data })
}

export function getLegalFiles(params: { legal_entity_id: number }) {
  return api.get<LegalFile[]>("get_legal_files", { params })
}

// TODO move to appropriate category
export function getDefaults() {
  return api.get<Defaults>("get_defaults")
}
