<template>
  <div>
    <input
      ref="input"
      v-model="value"
      :placeholder="props.placeholder"
      :type="props.type"
      :autofocus="props.autofocus"
      v-bind="props.inputAttrs"
      class="w-full rounded-sm border border-gray-200 bg-gray-50 p-2 outline-none transition focus:!border-blue-700 dark:border-gray-400 dark:bg-gray-600"
      :class="{
        'cursor-not-allowed opacity-70': props.disabled,
      }"
      :disabled="props.disabled"
    />
    <p
      v-if="props.maxLength"
      class="mt-1 text-right text-sm text-zinc-400"
    >
      {{ value?.length || 0 }}/{{ props.maxLength }}
    </p>
  </div>
</template>

<script setup lang="ts">
export interface VInputProps {
  maxLength?: number
  placeholder?: string
  type?: string
  autofocus?: boolean
  disabled?: boolean
  inputAttrs?: Record<string, string>
  allowedSymbols?: string
}

const props = defineProps<VInputProps>()

const value = defineModel<null | string>({ default: "" })
const input = ref<HTMLInputElement>()
useFocus(input, { initialValue: props.autofocus })

watchEffect(() => {
  if (!props.maxLength || !value.value) {
    return
  }

  if (value.value.length > props.maxLength) {
    value.value = value.value.slice(0, props.maxLength)
  }
})

watchEffect(() => {
  if (!props.allowedSymbols || !value.value) {
    return
  }

  value.value = value.value.replace(new RegExp(`[^${props.allowedSymbols}]`, "g"), "")
})
</script>
